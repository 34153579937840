$(document).ready(function(){
    if(checkModal()){
        openModal();
    }
    
    function checkModal(){
      return $('#notificationModalForm').length > 0 ? true : false;
    }
    
    function openModal(){
      $('#notificationModalForm').modal('show');
    }

    $("#notificationModalForm input[type=radio]").on("change", function() {
      console.log()
      if ($(this).is(":checked")) {
        $("#submit_btn_update_email_tracking").removeClass("disabled");
      }
    })

    $("#submit_btn_update_email_tracking").on("click", function(e) {
      var btn = $(this);
      var updateText = btn.data("update-text");

      btn.addClass("disabled");
      btn.text(updateText);
    })
});