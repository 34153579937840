// Plugins de plantilla pública
//require("packs/public/base.js");
require("packs/public/main.js");

/////////////////////////////////////
// **** Custom code de la plantlla pública ****
//
// Formulario de preguntas postulacion
require("packs/public/postulation_user.js");
// Filtros
require("packs/public/filter_public.js");
// Modal de notificaciones email marketing
require("packs/notification_form_modal.js");
// Modal de notificaciones
require("packs/notification_modal.js");
// Cambio de idioma
require("packs/language.js");

window.Sharer.init();
